export type FormFieldDirection = 'column' | 'row';

export enum SeazoneControlVersion {
  DEFAULT = '',
  V2 = 'v2',
  V3 = 'v3',
  V4 = 'v4'
}

export interface SeazoneControlSettings {
  themeVersion: SeazoneControlVersion;
  direction: FormFieldDirection;
  duplicateLabel: boolean;
  additionalClasses: string[];
}
