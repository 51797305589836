import {InjectionToken} from '@angular/core';

import {SeazoneControlSettings, SeazoneControlVersion} from '../models/control-settings';

export const SEAZONE_CONTROL_CONFIG = new InjectionToken('SeazoneFormField', {
  factory: (): SeazoneControlSettings => SEAZONE_COLUMN_CONTROL_CONFIG,
});

export const SEAZONE_COLUMN_CONTROL_CONFIG: SeazoneControlSettings = {
  themeVersion: SeazoneControlVersion.DEFAULT,
  direction: 'column',
  duplicateLabel: true,
  additionalClasses: [],
};

export const SEAZONE_ROW_CONTROL_CONFIG: SeazoneControlSettings = {
  themeVersion: SeazoneControlVersion.DEFAULT,
  direction: 'row',
  duplicateLabel: true,
  additionalClasses: [],
};

export const SEAZONE_V2_COLUMN_CONTROL_CONFIG: SeazoneControlSettings = {
  themeVersion: SeazoneControlVersion.V2,
  direction: 'column',
  duplicateLabel: true,
  additionalClasses: ['seazone-form-field--wrapper'],
};

export const SEAZONE_V2_ROW_CONTROL_CONFIG: SeazoneControlSettings = {
  themeVersion: SeazoneControlVersion.V2,
  direction: 'row',
  duplicateLabel: true,
  additionalClasses: ['seazone-form-field--wrapper'],
};

export const SEAZONE_V3_COLUMN_CONTROL_CONFIG: SeazoneControlSettings = {
  themeVersion: SeazoneControlVersion.V3,
  direction: 'column',
  duplicateLabel: true,
  additionalClasses: ['wrapper-rounded'],
};

export const SEAZONE_V3_ROW_CONTROL_CONFIG: SeazoneControlSettings = {
  themeVersion: SeazoneControlVersion.V3,
  direction: 'row',
  duplicateLabel: true,
  additionalClasses: ['wrapper-rounded'],
};

export const SEAZONE_V4_COLUMN_CONTROL_CONFIG: SeazoneControlSettings = {
  themeVersion: SeazoneControlVersion.V4,
  direction: 'column',
  duplicateLabel: true,
  additionalClasses: ['wrapper-rounded', 'wrapper-small'],
};

export const SEAZONE_V4_ROW_CONTROL_CONFIG: SeazoneControlSettings = {
  themeVersion: SeazoneControlVersion.V4,
  direction: 'row',
  duplicateLabel: true,
  additionalClasses: ['wrapper-rounded', 'wrapper-small'],
};
